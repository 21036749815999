import { differenceInDays, format } from 'date-fns'
import { getTotalImport } from 'screens/control-panel/PrescriberListScreen/helpers/getTotalImport'
import { matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { numberToEURCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'

export const getPrescribersByProfiles = (prescribers) => {
	return prescribers.investments.map((item) => {
		return {
			id: item._id,
			investorProfile: item.investorProfile.businessName,
			projectAddress: item.project.projectAddress,
			amount: item.amount,
			status: item.finishedProject?.length ? 'No Activa' : 'Activa',
			tir: item.investorTIR ? `${item.investorTIR} %` : 'Sin Tir',
			incomeRecivedDate: item.incomeRecivedDate
				? format(new Date(item.incomeRecivedDate), 'dd-MM-yyyy')
				: 'No confirmado',
			endDate: item.finishedProject[0]
				? format(new Date(item.finishedProject[0].createdAt), 'dd-MM-yyyy')
				: format(new Date(), 'dd-MM-yyyy'),
			bonus:
				item.incomeRecivedDate && item.amount
					? `${(
							((replaceStringToNum(item.amount) * 0.02) / 365) *
							Number(
								differenceInDays(
									item.finishedProject?.createdAt ? new Date(item.finishedProject?.createdAt) : new Date(Date.now()),
									new Date(item.incomeRecivedDate),
								),
							)
					  ).toFixed(2)} €`
					: `0.00 €`,
		}
	})
}

export const referalListByPrescriberMapper = (prescriberRefererListByIdData, periodFilterDate) => {
	return prescriberRefererListByIdData?.map((item) => {
		const liquidatedDate = item?.finished[0]?.createdAt ? new Date(item?.finished[0]?.createdAt) : null
		const startDate = periodFilterDate?.startDate
			? (new Date(`${item.incomeRecivedDate}T12:00:00.149+00:00`).valueOf() || 0) <
			  new Date(periodFilterDate.startDate).valueOf()
				? new Date(periodFilterDate.startDate)
				: new Date(`${item.incomeRecivedDate}T12:00:00.149+00:00`)
			: null
		const endDate = periodFilterDate?.endDate
			? !liquidatedDate
				? new Date(periodFilterDate.endDate)
				: liquidatedDate
			: null
		const days =
			periodFilterDate.startDate && periodFilterDate.endDate
				? startDate && endDate
					? `${Number(differenceInDays(endDate, startDate))}`
					: '0'
				: null

		return {
			id: item._id,
			investorProfile: item.investorProfile.businessName,
			projectAddress: item.project.projectAddress,
			amount: item.amount,
			incomeRecivedDate: item.incomeRecivedDate
				? item.incomeRecivedDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3-$2-$1')
				: 'No confirmado',
			liquidatedDate: liquidatedDate && liquidatedDate < endDate ? format(liquidatedDate, 'dd-MM-yyyy') : 'NL',
			startDate: startDate ? format(startDate, 'dd-MM-yyyy') : 'Seleccionar Fecha desde a Liquidar',
			endDate: endDate ? format(endDate, 'dd-MM-yyyy') : 'Seleccionar Fecha desde a Liquidar',
			days: days || 'Selecciona Periodo a Liquidar',
			bonus:
				periodFilterDate.startDate && periodFilterDate.endDate && item.amount
					? numberToEURCurrency((((replaceStringToNum(item.amount) * 0.02) / 365) * days).toFixed(2))
					: `0,00 €`,
			prescriptionLiquidatedDate: item.prescriptionLiquidatedDate
				? `${item.prescriptionLiquidatedDate} ${item.prescriptionLiquidatedHour}`
				: 'No Liquidado',
		}
	})
}

export const excelPrescribedInvestmentMapper = ({ filteredRows }) => {
	const excelRows = filteredRows?.map((item) => {
		return {
			id: item._id,
			investorProfile: item.businessName,
			projectAddress: item.projectAddress,
			amount: item.amount,
			incomeRecivedDate: item.incomeRecivedDate,
			liquidatedDate: item.liquidatedDate,
			startDate: item.startDate,
			endDate: item.endDate,
			days: item.days,
			bonus: item.bonus,
			prescriptionLiquidatedDate: item.prescriptionLiquidatedDate,
		}
	})

	excelRows[0] = {
		...excelRows[0],
		totalBonus: matchNumberToCurrency(getTotalImport(filteredRows?.map((item2) => item2?.bonus || 0))),
		totalImport: matchNumberToCurrency(getTotalImport(filteredRows?.map((item3) => item3?.amount || 0))),
	}

	return excelRows
}

export const excelMyPrescribedInvestmentMapper = ({ filteredRows }) => {
	const excelRows = filteredRows?.map((item) => {
		return {
			id: item._id,
			investorProfile: item.investorProfile || item.businessName,
			projectAddress: item.projectAddress,
			amount: item.amount,
			incomeRecivedDate: item.incomeRecivedDate,
			liquidatedDate: item.liquidatedDate,
			startDate: item.startDate,
			endDate: item.endDate,
			days: item.days,
			bonus: item.bonus,
			tir: item.tir,
			status: item.status,
			prescriptionLiquidatedDate: item.prescriptionLiquidatedDate,
		}
	})

	excelRows[0] = {
		...excelRows[0],
		totalBonus: matchNumberToCurrency(getTotalImport(filteredRows?.map((item2) => item2?.bonus || 0))),
		totalImport: matchNumberToCurrency(getTotalImport(filteredRows?.map((item3) => item3?.amount || 0))),
	}

	return excelRows
}
